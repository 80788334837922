import {
  ShareIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  RefreshIcon,
} from '@heroicons/react/outline'
import { atom, useAtom } from 'jotai'
import { AlertContainer } from '../alerts/AlertContainer'
import useConfirm from '../confirm/useConfirm'
import { InfoModal } from '../modals/InfoModal'
import NavLink from './NavLink'

export const infoModalOpenAtom = atom(false)
export const shareModalOpenAtop = atom(false)
export const settingsModalOpenAtom = atom(false)

type Props = {
  isGameWon: boolean
  resetGame: (() => void) | false
}

export const Navbar = ({ isGameWon, resetGame }: Props) => {
  const [, setInfoModalOpen] = useAtom(infoModalOpenAtom)
  const [, setShareModalOpen] = useAtom(shareModalOpenAtop)
  const [, setSettingsModalOpen] = useAtom(settingsModalOpenAtom)

  const confirm = useConfirm()
  const confirmResetGame = async () => {
    if (!resetGame) return

    confirm({
      title: 'Reset Game?',
      description: 'Are you sure you want to reset the game?',
    })
      .then(resetGame)
      .catch(() => {})
  }

  return (
    <div className="mb-2">
      <div className="flex h-12 items-center justify-between px-3">
        <div className="flex items-center gap-1">
          <p className="text-xl font-bold dark:text-white mr-2">Mordle</p>
          <NavLink to="/">Daily</NavLink>
          <NavLink to="/practice">Practice</NavLink>
        </div>
        <div className="flex gap-2 md:gap-3">
          {resetGame && (
            <RefreshIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={confirmResetGame}
            />
          )}
          {isGameWon && (
            <ShareIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setShareModalOpen(true)}
            />
          )}
          <QuestionMarkCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setInfoModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr />
      <InfoModal />
      <AlertContainer />
    </div>
  )
}
