import * as React from 'react'
import { BaseModal } from '../modals/BaseModal'

export type ConfirmOptions = {
  title: string
  description?: React.ReactNode
}

type Props = {
  open: boolean
  options: ConfirmOptions | null
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

const ConfirmationDialog: React.FC<Props> = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const { title, description } = options ?? {
    title: 'Confirm',
    description: '',
  }

  return (
    <BaseModal title={title} isOpen={open} handleClose={onClose}>
      <p className="dark:text-white mt-4">{description}</p>
      <div className="flex gap-4 mt-8 justify-end">
        <button
          // className="py-2 px-5 rounded-md bg-white hover:bg-gray-100 active:bg-gray-200 dark:text-white dark:bg-slate-800 dark:hover:bg-slate-600 dark:active:bg-slate-700 border border-slate-600"
          className="mt-2 rounded-md border border-slate-600 shadow-sm px-4 py-2 bg-white dark:bg-slate-800 text-base font-medium dark:text-white hover:bg-gray-100 dark:hover:bg-slate-600 active:bg-gray-200 dark:active:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:text-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 active:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          onClick={onConfirm}
        >
          Reset
        </button>
      </div>
    </BaseModal>
  )
}

export default ConfirmationDialog
