import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useAtom } from 'jotai'
import useUmami from '@parcellab/react-use-umami'
import { useEffect, useState } from 'react'
import { MAX_WORD_LENGTH, REVEAL_TIME_MS } from '../../constants/settings'
import {
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from '../../constants/strings'
import { useAlert } from '../../context/AlertContext'
import {
  saveGameStateToLocalStorage,
  StoredGameState,
} from '../../lib/localStorage'
import {
  getSolutions,
  getPracticeSolutions,
  isWordInWordList,
  unicodeLength,
} from '../../lib/words'
import { Grid } from '../grid/Grid'
import { Keyboard } from '../keyboard/Keyboard'
import { StatsModal } from '../modals/StatsModal'
import { infoModalOpenAtom, Navbar, shareModalOpenAtop } from '../navbar/Navbar'
import Celebrate from '../Celebrate'
import { SettingsModal } from '../modals/SettingsModal'

type Props = {
  gameKey: 'gameState' | 'practiceState'
  initialState: StoredGameState
}

const Game = ({ gameKey, initialState }: Props) => {
  const umamiEvent = useUmami('/game', null, null, true)
  const { showError, showSuccess } = useAlert()
  const [, setInfoModalOpen] = useAtom(infoModalOpenAtom)
  const [, setShareModalOpen] = useAtom(shareModalOpenAtop)

  const [currentGridIndex, setCurrentGridIndex] = useState(0)
  const [currentGuess, setCurrentGuess] = useState('')

  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isRevealing, setIsRevealing] = useState(false)

  const [newPlayer] = useState(() => !localStorage.getItem(gameKey))
  const [guesses, setGuesses] = useState<string[]>(initialState.guesses)
  const [maxChallenges, setMaxChallenges] = useState(initialState.maxChallenges)
  const [solutions, setSolutions] = useState(initialState.solutions)
  const [hardMode, setHardMode] = useState(initialState.hardMode)
  const [isGameWon, setIsGameWon] = useState(() =>
    initialState.solutions.every((solution) =>
      initialState.guesses.includes(solution)
    )
  )

  useEffect(() => {
    if (guesses.length === 0) return
    saveGameStateToLocalStorage(gameKey, {
      hardMode,
      guesses,
      date: new Date().toISOString().slice(0, 10),
      solutions,
      maxChallenges,
    })
  }, [gameKey, hardMode, guesses, solutions, maxChallenges])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH

      showSuccess(winMessage, {
        delayMs,
        onClose: () => setShareModalOpen(true),
      })
    }
  }, [isGameWon, showSuccess, setShareModalOpen])

  const clearCurrentRowClass = () => setCurrentRowClass('')
  const handleConfusion = () => {
    setInfoModalOpen(true)
    umamiEvent('Confused', 'click')
  }

  const onChar = (value: string) => {
    const guess = `${currentGuess}${value}`
    if (
      unicodeLength(guess) <= MAX_WORD_LENGTH &&
      guesses.length < maxChallenges &&
      !isGameWon
    ) {
      setCurrentGuess(guess)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon) return

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showError(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showError(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    const allWordsFound = solutions.every((solution) =>
      [...guesses, currentGuess].includes(solution)
    )

    setIsRevealing(true)
    // turn this back off after all chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
      if (allWordsFound) return

      if (solutions.includes(currentGuess) && !hardMode) {
        return setMaxChallenges((m) => m + 1)
      }

      if (guesses.length === maxChallenges - 1) {
        if (gameKey === 'gameState') {
          setSolutions((s) => getSolutions(s.length + 1))
        } else {
          setSolutions((s) => getPracticeSolutions(s, s.length + 1))
        }
        setMaxChallenges((m) => m + 2)
      }
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      guesses.length <= maxChallenges &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (allWordsFound) setIsGameWon(true)
    }
  }

  const onLeft = () =>
    setCurrentGridIndex(
      currentGridIndex === 0 ? solutions.length - 1 : currentGridIndex - 1
    )
  const onRight = () =>
    setCurrentGridIndex(
      currentGridIndex >= solutions.length - 1 ? 0 : currentGridIndex + 1
    )
  const resetGame =
    gameKey === 'practiceState' &&
    guesses.length > 0 &&
    (() => {
      setSolutions((s) => getPracticeSolutions([], 1))
      setMaxChallenges(2)
      setGuesses([])
      setIsGameWon(false)
    })

  return (
    <div className="h-screen flex flex-col">
      <Navbar isGameWon={isGameWon} resetGame={resetGame} />
      <div className="pt-2 px-1 w-full mx-auto sm:px-6 lg:px-8 flex flex-col grow items-center">
        <div className="pb-6 flex flex-wrap justify-center">
          {solutions.map((solution, index) => (
            <Grid
              key={solution}
              onClick={() => setCurrentGridIndex(index)}
              solution={solution}
              selected={currentGridIndex === index}
              maxChallenges={maxChallenges}
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
            />
          ))}
        </div>
        {newPlayer && guesses.length === 0 && (
          <button
            className="text-underline text-sm px-2 py-1 max-w-fit rounded border border-slate-500 text-slate-500 hover:bg-slate-200 dark:hover:bg-slate-800 active:bg-slate-300 dark:active:bg-slate-700 transition-all umami--click--confused-button"
            onClick={handleConfusion}
          >
            Why do I only have {maxChallenges} guesses?
          </button>
        )}
        <div className="flex grow"></div>
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          onLeft={onLeft}
          onRight={onRight}
          solution={solutions[currentGridIndex]}
          guesses={guesses}
          isRevealing={isRevealing}
        />
      </div>
      <SettingsModal
        modeChangeAllowed={guesses.length === 0}
        hardMode={hardMode}
        setHardMode={setHardMode}
      />
      <StatsModal
        guesses={guesses}
        solutions={solutions}
        resetGame={resetGame}
        hardMode={hardMode}
        isGameWon={isGameWon}
        handleShareToClipboard={() => showSuccess(GAME_COPIED_MESSAGE)}
        numberOfGuessesMade={guesses.length}
      />
      {isGameWon && <Celebrate />}
    </div>
  )
}

export default Game
