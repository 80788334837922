import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import { useAtom } from 'jotai'
import { settingsModalOpenAtom } from '../navbar/Navbar'
import darkModeAtom from '../../atoms/darkMode'
import highContrastModeAtom from '../../atoms/highContrastMode'

type Props = {
  modeChangeAllowed: boolean
  hardMode: boolean
  setHardMode: (value: boolean) => void
}

export const SettingsModal = ({
  modeChangeAllowed,
  hardMode,
  setHardMode,
}: Props) => {
  const [settingsModalOpen, setSettingsModalOpen] = useAtom(
    settingsModalOpenAtom
  )
  const [darkMode, setDarkMode] = useAtom(darkModeAtom)
  const [highContrastMode, setHighContrastMode] = useAtom(highContrastModeAtom)
  return (
    <BaseModal
      title="Settings"
      isOpen={settingsModalOpen}
      handleClose={() => setSettingsModalOpen(false)}
    >
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle
          settingName="Dark Mode"
          flag={darkMode}
          handleFlag={setDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={highContrastMode}
          handleFlag={setHighContrastMode}
          description="For improved color vision"
        />
        <SettingsToggle
          settingName="Hard Mode"
          flag={hardMode}
          handleFlag={setHardMode}
          description="No extra guess when you get a word"
          disabled={!modeChangeAllowed}
        />
      </div>
    </BaseModal>
  )
}
