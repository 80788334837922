import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'

const Celebrate = () => {
  const { width, height } = useWindowSize()
  const [play, setPlay] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setPlay(false), 3000)
    return () => clearTimeout(timer)
  }, [])
  return <Confetti width={width} height={height} recycle={play} />
}

export default Celebrate
