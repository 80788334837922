import { useAtom } from 'jotai'
import { useState } from 'react'
import useUmami from '@parcellab/react-use-umami'
import { WELCOME_INFO_MODAL_MS } from '../../constants/settings'
import { loadGameStateFromLocalStorage } from '../../lib/localStorage'
import { getPracticeSolutions } from '../../lib/words'
import { infoModalOpenAtom } from '../navbar/Navbar'
import Game from './Game'

const Practice = () => {
  useUmami('/practice')

  const [, setInfoModalOpen] = useAtom(infoModalOpenAtom)
  const [initialState] = useState(() => {
    const today = new Date().toISOString().slice(0, 10)
    let state = loadGameStateFromLocalStorage('practiceState')
    if (!state) setTimeout(() => setInfoModalOpen(true), WELCOME_INFO_MODAL_MS)
    state ??= {
      guesses: [],
      maxChallenges: 2,
      solutions: getPracticeSolutions([], 1),
      hardMode: false,
      date: today,
    }
    return state
  })

  return <Game gameKey="practiceState" initialState={initialState} />
}

export default Practice
