import React, { useState, useCallback, Fragment } from "react"
import ConfirmContext from "./ConfirmContext"
import ConfirmationDialog, { ConfirmOptions } from "./ConfirmationDialog"

const ConfirmProvider = ({
  children,
}: {
  children: any
}) => {
  const [options, setOptions] = useState<ConfirmOptions|null>(null)
 
  const [resolveReject, setResolveReject] = useState<any[]>([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback((options = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(options)
      setResolveReject([resolve, reject])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    reject()
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve()
    handleClose()
  }, [resolve, handleClose])

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  )
}

export default ConfirmProvider
