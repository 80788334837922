import { MAX_WORD_LENGTH } from '../../constants/settings'
import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'
import { forwardRef } from 'react'

type Props = {
  guess: string
  className: string
  isRevealing?: boolean
}

export const CurrentRow = forwardRef(
  (
    { guess, className, isRevealing }: Props,
    ref: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    const splitGuess = unicodeSplit(guess)
    const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))
    const classes = `inline-flex justify-center mb-1 ${className}`

    return (
      <div className={classes} ref={ref}>
        {splitGuess.map((letter, i) => (
          <Cell key={i} value={letter} current isRevealing={isRevealing} />
        ))}
        {emptyCells.map((_, i) => (
          <Cell key={i} current isRevealing={isRevealing} />
        ))}
      </div>
    )
  }
)
