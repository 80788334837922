import { useEffect, useMemo, useRef } from 'react'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  guesses: string[]
  solution: string
  selected: boolean
  maxChallenges: number
  onClick: () => void
  currentGuess: string
  isRevealing?: boolean
  currentRowClassName: string
}

export const Grid = ({
  guesses,
  solution,
  selected,
  maxChallenges,
  onClick,
  currentGuess,
  isRevealing,
  currentRowClassName,
}: Props) => {
  const gridWon = guesses.includes(solution)
  const upToCorrectGuesses = gridWon
    ? guesses.slice(0, guesses.indexOf(solution) + 1)
    : guesses

  const currentRowRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    selected &&
      currentRowRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
  }, [selected])

  const Completed = useMemo(() => {
    return upToCorrectGuesses.map((guess, i) => (
      <CompletedRow
        key={i}
        guess={guess}
        solution={solution}
        isRevealing={isRevealing && guesses.length - 1 === i}
      />
    ))
  }, [upToCorrectGuesses, solution, isRevealing, guesses.length])

  const Current = useMemo(() => {
    return (
      !gridWon &&
      guesses.length < maxChallenges && (
        <CurrentRow
          ref={currentRowRef}
          guess={currentGuess}
          className={currentRowClassName}
          isRevealing={isRevealing}
        />
      )
    )
  }, [
    currentRowRef,
    currentGuess,
    currentRowClassName,
    guesses.length,
    gridWon,
    maxChallenges,
    isRevealing,
  ])

  const Empties = useMemo(() => {
    const empties = gridWon
      ? Array.from(Array(maxChallenges - upToCorrectGuesses.length))
      : guesses.length < maxChallenges - 1
      ? Array.from(Array(maxChallenges - 1 - guesses.length))
      : []

    return empties.map((_, i) => <EmptyRow key={i} />)
  }, [guesses, maxChallenges, gridWon, upToCorrectGuesses])

  return (
    <div
      className={`inline-flex flex-col p-2 lg:p-3 ${
        selected ? 'bg-green-700/70' : ''
      } rounded-md md:rounded-lg lg:rounded-xl`}
      onClick={onClick}
    >
      {Completed}
      {Current}
      {Empties}
    </div>
  )
}
