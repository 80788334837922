import { useAtom } from 'jotai'
import { Cell } from '../grid/Cell'
import { infoModalOpenAtom } from '../navbar/Navbar'
import { BaseModal } from './BaseModal'

const Link = ({
  children,
  href,
}: {
  children: React.ReactNode
  href: string
}) => (
  <a href={href} className="underline font-bold">
    {children}
  </a>
)

export const InfoModal = () => {
  const [infoModalOpen, setInfoModalOpen] = useAtom(infoModalOpenAtom)
  return (
    <BaseModal
      title="How to play"
      isOpen={infoModalOpen}
      handleClose={() => setInfoModalOpen(false)}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in each grid. After each guess, the color of the tiles
        will change to show how close your guess was to the word. Keyboard hints
        will be shown for the green selected grid.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell current value="W" status="correct" />
        <Cell current value="E" />
        <Cell current value="A" />
        <Cell current value="R" />
        <Cell current value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell current value="P" />
        <Cell current value="I" />
        <Cell current value="L" status="present" />
        <Cell current value="O" />
        <Cell current value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell current value="V" />
        <Cell current value="A" />
        <Cell current value="G" />
        <Cell current value="U" status="absent" />
        <Cell current value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 mb-8">
        The letter U is not in the word in any spot.
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300 mb-8">
        If you get two incorrect guesses in a row, no worries! You'll just have
        to guess another word.
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
        In the beginning there was{' '}
        <Link href="https://www.powerlanguage.co.uk/wordle/">Wordle</Link>. Then
        came <Link href="https://zaratustra.itch.io/dordle">Dordle</Link>. Then{' '}
        <Link href="https://www.quordle.com/">Quordle</Link>.{' '}
        <Link href="">Octordle</Link>.{' '}
        <Link href="https://www.sedecordle.com">Secdecdordle</Link>.
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        What if you could just keep going?
      </p>

      <p className="mt-6 italic text-xs text-gray-400 dark:text-gray-500">
        Based on{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          react-wordle
        </a>{' '}
      </p>
    </BaseModal>
  )
}
