import { getGuessStatuses } from './statuses'
import { solutionIndex, unicodeSplit } from './words'
import { GAME_TITLE } from '../constants/strings'
import { UAParser } from 'ua-parser-js'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  guesses: string[],
  solutions: string[],
  practice: boolean,
  hardMode: boolean,
  darkMode: boolean,
  highContrastMode: boolean,
  handleShareToClipboard: () => void
) => {
  let scores = solutions.map((solution) => guesses.indexOf(solution) + 1)
  if (scores.includes(0)) scores = []
  const textToShare =
    `${GAME_TITLE} ${practice ? "practice" : solutionIndex}${hardMode ? '*' : ''}\nWords: ${solutions.length}\nGuesses: ${scores.join(', ')}\n\n` +
    generateEmojiGrid(
      guesses,
      getEmojiTiles(darkMode, highContrastMode),
      solutions
    ) +
    '\n\nmordle.aaronolsen.ca'

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  if (!shareSuccess) {
    navigator.clipboard.writeText(textToShare)
    handleShareToClipboard()
  }
}

export const generateEmojiGrid = (
  guesses: string[],
  tiles: string[],
  solutions: string[]
) => {
  return solutions
    .map((solution) =>
      guesses
        .map((guess, i) => {
          if (guesses.indexOf(solution) < i) return ''

          const status = getGuessStatuses(guess, solution)
          const splitGuess = unicodeSplit(guess)

          return splitGuess
            .map((_, i) => {
              switch (status[i]) {
                case 'correct':
                  return tiles[0]
                case 'present':
                  return tiles[1]
                default:
                  return tiles[2]
              }
            })
            .join('')
        })
        .filter(Boolean)
        .join('\n')
    )
    .join('\n⸺⸺⸺⸺\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')
  tiles.push(isHighContrastMode ? '🟦' : '🟨')
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}
