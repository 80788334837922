import { useAtom } from 'jotai'
import Countdown from 'react-countdown'
import darkModeAtom from '../../atoms/darkMode'
import highContrastModeAtom from '../../atoms/highContrastMode'
import { SHARE_TEXT, WIN_TITLE } from '../../constants/strings'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { shareModalOpenAtop } from '../navbar/Navbar'
import { BaseModal } from './BaseModal'

type Props = {
  guesses: string[]
  solutions: string[]
  resetGame: false | (() => void)
  hardMode: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  numberOfGuessesMade: number
}

export const StatsModal = ({
  guesses,
  solutions,
  resetGame,
  hardMode,
  isGameWon,
  handleShareToClipboard,
}: Props) => {
  const [shareModalOpen, setShareModalOpen] = useAtom(shareModalOpenAtop)

  const [darkMode] = useAtom(darkModeAtom)
  const [highContrastMode] = useAtom(highContrastModeAtom)

  return (
    <BaseModal
      title={WIN_TITLE}
      isOpen={shareModalOpen && isGameWon}
      handleClose={() => setShareModalOpen(false)}
    >
      {hardMode && <p className="dark:text-white">You beat hard mode!</p>}
      {isGameWon && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            {resetGame ? (
              <button
                type="button"
                onClick={resetGame}
                className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              >
                New Game
              </button>
            ) : (
              <>
                <h5>New game in:</h5>
                <Countdown
                  className="text-lg font-medium text-gray-900 dark:text-gray-100"
                  date={tomorrow}
                  daysInHours={true}
                />
              </>
            )}
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                guesses,
                solutions,
                !!resetGame,
                hardMode,
                darkMode,
                highContrastMode,
                handleShareToClipboard
              )
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
    </BaseModal>
  )
}
