import { BackspaceIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { getStatuses } from '../../lib/statuses'
import { localeAwareUpperCase } from '../../lib/words'
import { Key } from './Key'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  onLeft: () => void
  onRight: () => void
  solution: string
  guesses: string[]
  isRevealing?: boolean
}

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
  onLeft,
  onRight,
  solution,
  guesses,
  isRevealing,
}: Props) => {
  const charStatuses = getStatuses(guesses, solution)

  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else if (value === '⇦') {
      onLeft()
    } else if (value === '⇨') {
      onRight()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else if (e.code === 'ArrowLeft') {
        onLeft()
      } else if (e.code === 'ArrowRight') {
        onRight()
      } else {
        const key = localeAwareUpperCase(e.key)
        // TODO: check this test if the range works with non-english letters
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onChar(key)
        }
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [onEnter, onDelete, onLeft, onRight, onChar])

  return (
    <div className="sticky bottom-0">
      <div className="dark:bg-[#0f172a] max-w-fit m-auto p-1 rounded-tl-xl rounded-tr-xl">
        <div className="flex justify-center mb-1">
          {['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map((key) => (
            <Key
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[key]}
              isRevealing={isRevealing}
            />
          ))}
        </div>
        <div className="flex justify-center mb-1">
          <Key value="⇦" onClick={onClick} />
          <span className="w-1"></span>
          {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((key) => (
            <Key
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[key]}
              isRevealing={isRevealing}
            />
          ))}
          <span className="w-1"></span>
          <Key value="⇨" onClick={onClick} />
        </div>
        <div className="flex justify-center">
          <Key width={68} value="ENTER" onClick={onClick}>
            Enter
          </Key>
          {['Z', 'X', 'C', 'V', 'B', 'N', 'M'].map((key) => (
            <Key
              value={key}
              key={key}
              onClick={onClick}
              status={charStatuses[key]}
              isRevealing={isRevealing}
            />
          ))}
          <Key width={68} value="DELETE" onClick={onClick}>
            <BackspaceIcon className="dark:stroke-white h-4/6" />
          </Key>
        </div>
      </div>
    </div>
  )
}
