import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Daily from './components/game/Daily'
import Practice from './components/game/Practice'
import useDisplayPreferences from './lib/useDisplayPreferences'
import { registerUmamiScript } from '@parcellab/react-use-umami'

const App = () => {
  useDisplayPreferences()
  useEffect(() => {
    registerUmamiScript(
      'https://umami.aaronolsen.ca/umami.js',
      '676a8773-5bd8-4a57-ba30-ad61f9d4ef37',
      'mordle.aaronolsen.ca'
    )
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Daily />} />
        <Route path="practice" element={<Practice />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
