import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom'

const NavLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link
      className={`text-sm dark:text-white px-2 py-1.5 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition-all ${
        match ? ' bg-gray-300 dark:bg-gray-600 ' : ''
      }`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  )
}

export default NavLink
