const STORAGE_VERSION = 1

export type StoredGameState = {
  hardMode: boolean
  guesses: string[]
  date: string
  solutions: string[]
  maxChallenges: number
}

export const saveGameStateToLocalStorage = (
  gameKey: string,
  gameState: StoredGameState
) => {
  localStorage.setItem(
    gameKey,
    JSON.stringify({ ...gameState, version: STORAGE_VERSION })
  )
}

export const loadGameStateFromLocalStorage = (gameKey: string) => {
  const state = localStorage.getItem(gameKey)
  if (state) {
    const parsed = JSON.parse(state)
    if (parsed.version === STORAGE_VERSION) {
      return parsed as StoredGameState
    }
  }
  return null
}
