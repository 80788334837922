import { atomWithStorage } from 'jotai/utils'
const prefersHighContrast = window.matchMedia(
  '(prefers-contrast: more)'
).matches

const highContrastModeAtom = atomWithStorage(
  'highContrastMode',
  prefersHighContrast
)

export default highContrastModeAtom
