import { useAtom } from 'jotai'
import useUmami from '@parcellab/react-use-umami'
import { useState } from 'react'
import { WELCOME_INFO_MODAL_MS } from '../../constants/settings'
import { loadGameStateFromLocalStorage } from '../../lib/localStorage'
import { getSolutions } from '../../lib/words'
import { infoModalOpenAtom } from '../navbar/Navbar'
import Game from './Game'

const Daily = () => {
  useUmami('/')

  const [, setInfoModalOpen] = useAtom(infoModalOpenAtom)
  const [initialState] = useState(() => {
    const today = new Date().toISOString().slice(0, 10)
    let state = loadGameStateFromLocalStorage('gameState')
    if (!state) setTimeout(() => setInfoModalOpen(true), WELCOME_INFO_MODAL_MS)
    state ??= {
      guesses: [],
      maxChallenges: 2,
      solutions: getSolutions(1),
      hardMode: false,
      date: today
    }
    if (state.date !== today) {
      state.guesses = []
      state.maxChallenges = 2
      state.solutions = getSolutions(1)
    }
    return state
  })
  return <Game gameKey="gameState" initialState={initialState} />
}

export default Daily
