import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'
import { REVEAL_TIME_MS } from '../../constants/settings'
import { useMemo } from 'react'
import { useAtom } from 'jotai'
import highContrastModeAtom from '../../atoms/highContrastMode'

type Props = {
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  isCompleted?: boolean
  current?: boolean
  solved?: boolean
  position?: number
}

export const Cell = ({
  value,
  status,
  isRevealing,
  isCompleted,
  current,
  solved,
  position = 0,
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`
  const [highContrast] = useAtom(highContrastModeAtom)

  const classes = classnames(
    `w-7 sm:w-9 md:w-11 lg:w-14 border-solid border-[1px] md:border-2 flex items-center justify-center mx-px lg:mx-0.5 font-bold rounded dark:text-white ${
      solved || !!current !== !!isRevealing
        ? 'h-7 sm:h-9 md:h-11 lg:h-14 text-xl sm:text-2xl md:text-3xl lg:text-4xl'
        : 'h-4 sm:h-5 md:h-6 lg:h-8 text-sm sm:text-lg md:text-xl lg:text-2xl'
    } transition-all`,
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && highContrast,
      'present shadowed bg-cyan-500 text-white border-cyan-500':
        status === 'present' && highContrast,
      'correct shadowed bg-green-500 text-white border-green-500':
        status === 'correct' && !highContrast,
      'present shadowed bg-yellow-500 text-white border-yellow-500':
        status === 'present' && !highContrast,
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  )

  return useMemo(
    () => (
      <div className={classes} style={{ animationDelay }}>
        <div className="letter-container" style={{ animationDelay }}>
          {value}
        </div>
      </div>
    ),
    [value, animationDelay, classes]
  )
}
