import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { default as GraphemeSplitter } from 'grapheme-splitter'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  )
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getWordsOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date(2022, 0).valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    getSolutions: (amount: number) => {
      const solutions: string[] = []
      let idx = index
      for (let i = 0; i < amount; i++) {
        let solution
        do {
          solution = localeAwareUpperCase(WORDS[idx++ % WORDS.length])
        } while (solutions.includes(solution))
        solutions.push(solution)
        idx += [...solution].reduce((total, c) => (total * c.charCodeAt(0)) % WORDS.length, 1)
      }
      return solutions
    },
    getPracticeSolutions: (oldSolutions: string[], amount: number) => {
      const solutions = [...oldSolutions]
      let solution
      do {
        do {
          solution = getRandomWord()
        } while (solutions.includes(solution))
        solutions.push(solution)
      } while (solutions.length < amount)
      return solutions
    },
    solutionIndex: index,
    tomorrow: nextday,
  }
}

const getRandomWord = () =>
  localeAwareUpperCase(
    WORDS[Math.floor(Math.random() * WORDS.length) % WORDS.length]
  )

// @ts-ignore
window.getPracticeSolutions = getWordsOfDay().getPracticeSolutions
export const { getSolutions, getPracticeSolutions, solutionIndex, tomorrow } =
  getWordsOfDay()
