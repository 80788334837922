import { useAtom } from 'jotai'
import { useEffect } from 'react'
import darkModeAtom from '../atoms/darkMode'
import highContrastModeAtom from '../atoms/highContrastMode'

const useDisplayPreferences = () => {
  const [darkMode] = useAtom(darkModeAtom)
  const [highContrastMode] = useAtom(highContrastModeAtom)
  useEffect(() => {
    const classes = document.documentElement.classList
    const apply = (key: string, cond: boolean) =>
      cond ? classes.add(key) : classes.remove(key)
    apply('dark', darkMode)
    apply('high-contrast', highContrastMode)
  }, [darkMode, highContrastMode])
}

export default useDisplayPreferences
