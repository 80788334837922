import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'
import { useMemo } from 'react'

type Props = {
  solution: string
  guess: string
  isRevealing?: boolean
}

export const CompletedRow = ({ solution, guess, isRevealing }: Props) => {
  const statuses = getGuessStatuses(guess, solution)
  const splitGuess = unicodeSplit(guess)
  const solved = statuses.every((s) => s === 'correct')

  return useMemo(
    () => (
      <div className="inline-flex justify-center mb-1">
        {splitGuess.map((letter, i) => (
          <Cell
            key={i}
            value={letter}
            status={statuses[i]}
            position={i}
            isRevealing={isRevealing}
            isCompleted
            solved={solved}
          />
        ))}
      </div>
    ),
    [splitGuess, statuses, isRevealing, solved]
  )
}
